<template>

         <p ref="input"></p>

</template>

<script>
    export default {
        props: ['paragraph', 'link'],
        mounted() {
            //console.log('Component mounted.');
            //console.log(this.paragraph);
            //console.log(this.$refs.input);



            let paragraph = this.$refs.input;
            //console.log(this.$refs.i);
            // Remove quotes from front and back of json
            let str = this.paragraph.slice(1, -1);

            // Ensure that double quotes within description display properly
            str = str.split("\\\"").join('"');

            // Ensure forward slashes display properly
            str = str.replace(/\\\/|\/\\/g, "/");
            paragraph.innerText = str;
            if(str.includes("troubleshooting guide (link).")){
                // Remove end of string and replace contents
                str = str.replace("troubleshooting guide (link).", "");
                paragraph.innerText = str;
                
                // Create Link and append it to p tag
                let myLink = document.createElement('a');
                myLink.innerHTML = "troubleshooting guide.";
                myLink.href = this.link;
                paragraph.appendChild(myLink);
            }



        },
        data(){


            return {
                likes: 0,
            }
        }
    }
</script>
